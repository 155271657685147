.container {
    display: flex;
    /*flex-flow: column;*/

    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 0.2rem;
    margin-bottom: 0.2rem;

    .containerData {
        display: flex;
        flex-flow: column;

        /*width: 70%;*/

        .title {
            padding: 0 0.2rem;
            /*font-size: 10px;*/
            font-size: 0.8rem;

            color: #696868;
            justify-content: start;
        }

        .text {
            display: flex;
            padding: 0.1rem 0.2rem;
            font-size: 0.9rem;

            color: #E0E0E0;
            height: 100%;

            align-items: center;
        }

        .description {
            display: flex;
            padding: 0.1rem 0.2rem;

            font-size: 0.8rem;
            color: #9E9E9E;

            align-items: center;
        }
    }

    .containerActions {
        display: flex;
        /*margin-left: auto;*/
        color: #E0E0E0;
        padding-right: 0.4rem;
    }
}

.buttonsContainer {
    display: flex;
    margin-top: auto;
    /*!*margin-bottom: 0*!*/
    /*align-self: end;*/
    justify-content: center;
    color: #E0E0E0;
    /*width: 100%;*/
}


.rigs {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.rigs__list {
    display: grid;
    flex-shrink: 1;
    min-width: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content;
    overflow: auto;
}


.rigSettings__hint {
    font-size: 12px;
    width: 100%;
    padding: 2px 0;
    text-align: center;
    color: #E0E0E0;
    /*margin-top: 12px;*/
}

.rigSettings__buttons {
    display: grid;
    text-align: center;
    color: #E0E0E0;
    font-size: 12px;
    margin: 4px;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.rigsSettings__container {
    margin-top: auto;
}

.rigSettings__overclock {
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 4px;

    color: #E0E0E0;
    font-size: 12px;
    margin: 4px;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .rigSettings__overclock__description {
        width: 50%;
    }
    .rigSettings__overclock__optionsContainer {
        /*width: 60%;*/
        display: flex;
        flex-grow: 1;
        /*flex-direction: row;*/
        justify-content: space-between;

        .rigSettings__overclock__option {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
    }
}
.rigSettings__overclock_selected {
    border: 1px solid red;
}
