.button {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: radial-gradient(circle at 10% 20%, #253237FF 0%, #0E1E2577 100.2%);
    padding: 2px 8px 2px 8px;
    gap: 8px;
    min-width: 55px;
    /*margin-left: 1px;*/
    /*margin-right: 1px;*/
    user-select: none !important;
    cursor: pointer;
}

.button__selected {
    border: 1px solid #57ec38;
}

.button__disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.button:hover {
    background: #253237FF
}

.button:active {
    background: #253237FF
}

.button__text {
    font-size: 0.9rem;
    color: #E0E0E0;
    margin-bottom: 1px;
}

.button__image {
    margin-top: 2px;
}
