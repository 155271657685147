.shopItem {
    display: flex;
    flex-flow: row;
    align-items: center;
    /*padding: 12px;*/
    padding: 0.2rem 0.8rem;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem;
    background-color: #424242;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 65px;
    user-select: none; /* disable text selection */
}

.shopItem__img {
    margin-right: 0.4rem;
    width: 50px;
    height: 70px;
}

.shopItem__info {
    padding: 0.4rem;
    flex-grow: 1;
    height: 90%;
    width: 50%;
}

.shopItem__description {
    /*font-size: 10px;*/
    font-size: 0.9rem;
    color: #9E9E9E;
}

.shopItem__title {
    font-size: 1.1rem;

    color: white;
    margin-bottom: 0.2rem;
}

.shopItem__price {
    min-width: 20px;
    color: #E0E0E0;
    margin-bottom: 0.2rem;
    margin-left: 0.4rem;
}

.shopItem__priceCaption {
    font-size: 0.8rem;
    margin-top: 0.8rem;
    color: #43A047;
}
