.rig {
    margin: 2px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
    border-radius: 6px;
    padding-bottom: 0;
    border: #212121 1px solid;
    aspect-ratio: 1;
}

.rig_empty {
    /*margin: 2px;*/
    /*display: flex;*/
    /*align-items: center;*/

    /*justify-content: center;*/
    /*border: #212121 1px solid;*/
    background-color: rgba(68, 68, 68, 0.78);
}

.emptyRigLogoContainer {
    padding-top: 0.2rem;
    font-size: 2rem;
    color: rgba(224, 224, 224, 0.58);

}

.rig_enabled {
    background: rgb(67, 179, 73);
    background: radial-gradient(circle, rgba(67, 179, 73, 1) 0%, rgba(33, 112, 39, 1) 100%);
}

.rig_disabled {
    background: rgb(244, 126, 117);
    background: radial-gradient(circle, rgba(244, 126, 117, 1) 0%, rgba(205, 83, 72, 1) 100%);
}

.rig_selected {
    border: 3px solid white !important;
}

.rig__indicator {
    width: 17px;
    height: 17px;
    padding: 5px;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid white;
    margin-top: 3px;
}

.rig__indicator_enabled {
    background-color: #2E7D32;
    color: #1B5E20;
}

.rig__indicator_enabled:hover {
    background-color: #1B5E20;
    color: black;
}

.rig__indicator_disabled:hover {
    background-color: #B71C1C;
    color: #731616;
}

.rig__indicator_disabled {
    background-color: #D50000;
    color: #8f1212;
}

.rig__name {
    background-color: rgba(33, 33, 33, 0.35);
    padding: 3px 0;
    font-size: 16px;
    width: 100%;
    text-align: center;
    color: #E0E0E0;
}
