.miner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0.8rem;
    box-sizing: border-box;
    overflow: hidden
}

.miner__container {
    width: 100%;
    max-width: 400px;
    height: 100%;

    border: 1px solid #E0E0E0;
    border-radius: 0.4rem;
    padding: 0.4rem;
    margin: 0.4rem;

    background: linear-gradient(109.6deg, rgb(0, 0, 0) 11.2%, rgb(11, 132, 145) 91.1%);

    flex-shrink: 1;
    overflow: auto;
}

