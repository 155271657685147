.main {
    display: flex;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    flex-direction: column;
    text-align: center;
}

.header {
    width: 100%;
    text-align: center;
    font-weight: bold;
}
.text {
    text-indent: 0.5rem;
}