.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 6px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #424242;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 65px;
    line-height: 1.4rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;

    .rowSection {
        display: flex;
        flex-direction: column;
    }

    .rowSection:nth-child(1) {
        .title {
            font-size: 16px;
            color: white;
            /*margin-bottom: 4px;*/
        }

        .descriptionSection {
            margin-top: auto;
            font-size: 0.8rem;
            color: #9E9E9E;
        }
    }

    .rowSection:nth-child(2) {
        margin-left: auto;
        padding-left: 1rem;

        .priceSection {
            .itemPrice {
                min-width: 5rem;
                display: inline-block;
                white-space: nowrap;
                color: #E0E0E0;
                margin-right: 0.2rem;
                border: 0.1rem solid #E0E0E0;
                border-radius: 0.2rem;
                padding: 0.2rem;
                text-align: center;
            }
            .itemPrice:nth-last-child(1) {
                margin-right: 0 !important;
            }
        }

        .buttonSection {
            margin-top: auto;
            margin-left: auto;
        }
    }
}
