.nftItem {
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    /*background-color: #424242;*/
    /*!*overflow-x: hidden;*!*/
    /*!*overflow-y: hidden;*!*/
    /*!*min-height: 65px;*!*/

    user-select: none; /* disable text selection */
    padding: 5px;
}

.nftItemImageConatainer {
    /*display: flex;*/
    /*justify-content: center;*/
    align-content: center;
}
.nftItemImage {
    /*margin-right: 16px;*/
    width: 90px;
    height: 90px;
    border-radius: 1.5rem;
}
.nftItemData {
    padding-left: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.nftItemName {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    color: #E0E0E0;
}
