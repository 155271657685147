.row {
    display: flex;
    justify-content: center;
    color: #E0E0E0;
}
.inputLabel{
    margin-bottom: 0.4rem;
}

.container {
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
}

.buttonsContainer {
    display: flex;
    margin-top: 0.6rem;
    justify-content: center;
    color: #E0E0E0;
}

.description {
    padding: 0.2rem;
    font-size: 0.8rem;
    color: #9E9E9E;
}
