.navbar {
    width: 100%;
    max-width: 400px;

    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem;
    padding: 0 0.4rem;

    display: flex;
    flex-flow: row;
    justify-content: space-between;

    background: radial-gradient(circle at 50% 20%, rgba(96, 180, 204, 0.47) 0%, rgb(37, 50, 55) 100.2%);
}

.navbar__item {
    display: flex;
    flex-flow: column;
    margin: 4px;
    padding: 4px;
    text-align: center;
    border-radius: 8px;
    flex-grow: 1;
    flex-basis: 0;
    width: 70px;
    cursor: pointer;
}

.navbar__item:active {
    background-color: #B5DEEF79;
}

.navbar__item_current {
    background-color: #B5DEEF79;
}

.navbar__item__icon {
    color: #E0E0E0;
    margin-top: 8px;
    flex-grow: 1;
    flex-basis: 0;
}

.navbar__item__text {
    color: #E0E0E0;
    font-size: 11px;
    flex-grow: 1;
    vertical-align: center;
}
