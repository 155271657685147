.rouletteButtonContainer {
    margin-top: 1rem;
    /*background-color: green;*/

    .rouletteButtonDiv {
        display: flex;
        /*background-color: red;*/
        justify-content: center;
        /*align-content: center;*/
        /*height: 10rem;*/

        .rouletteButton {
            /*background-color: #ffa438;*/
            display: flex;
            justify-content: center;
            border-radius: 0.4rem;
            border: 0.1rem solid #eae129;
            background: radial-gradient(circle at 10% 20%, #eae129 0%, #f9a526 100.2%);
            padding: 0.5rem 0.4rem;
            /*gap: 8px;*/
            min-width: 55px;
            /*margin-left: 1px;*/
            /*margin-right: 1px;*/
            user-select: none !important;
            cursor: pointer;
            height: 3rem;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 550;
        }

        .rouletteButton:hover {
            /*border: 1px solid #57ec38;*/
            box-shadow: 0 .5em 1em 0 rgba(0, 0, 0, 0.15), 0 .4em .5em -.4em rgba(0, 0, 0, 0.4);
        }

        .rouletteButton:active {
            /*background-color: #3e8e41;*/
            /*box-shadow: 0 5px #666;*/
            transform: translateY(0.1rem);
        }

        .rouletteButton:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        /*.button__disabled {*/
        /*    opacity: 0.3;*/
        /*    cursor: not-allowed;*/
        /*}*/

        /*.button:hover {*/
        /*    background: #253237FF*/
        /*}*/

        /*.button:active {*/
        /*    background: #253237FF*/
        /*}*/
    }
}
