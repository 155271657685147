.dealerItemContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 6px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #424242;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 65px;
    line-height: 1.4rem;
}

.dealerItemRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dealerItemSlider {
    line-height: 0.1rem;
    width: 90%;
}

.dealerItemPrice {
    color: #E0E0E0;
    margin-left: auto;
}

.titleInfoContainer {
/*    padding: 8px;*/
/*    flex-grow: 1;*/
/*    height: 90%;*/
/*    width: 50%;*/
}

.count {
    font-size: 0.8rem;
    color: #9E9E9E;
}

.totalPrice {
    font-size: 0.8rem;
    color: #9E9E9E;
}

.title {
    font-size: 16px;
    color: white;
    /*margin-bottom: 4px;*/
}
