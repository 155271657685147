.shop {
    display: flex;
    flex: 1 1 0;
    flex-flow: column;
    gap: 4px;
    width: 100%;
    overflow-x: auto;
    /*height: 100%;*/
    /*min-height: 100%;*/
}

.shopButtons {
    display: flex;
    margin-top: auto;
    /*!*margin-bottom: 0*!*/
    /*align-self: end;*/
    justify-content: space-between;
    color: #E0E0E0;
    /*width: 100%;*/
}
