.main {
    display: flex;
    flex-direction: column;
}

.blows {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.moves {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 0.5rem;
}

.other {
    width: 100%;
    /*align-items: center;*/
    /*align-content: center;*/
    /*text-align: center;*/
}

.left {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: end;
}

.right {
    width: 50%;
}

.button {
    margin: 0.15rem;
    cursor: pointer;
}