.main {
    display: flex;
}

.fighterContainer {
    width: 45%;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-weight: bold;
    font-size: 1.2rem;
}

.vs {
    width: 10%;
    color: darkred;
    font-weight: bold;
}

.fighterContainer:nth-child(3) {
    margin-left: auto;
    align-content: end;
    text-align: end;
}

