.arenaDialog {
    display: flex;
    flex-flow: column;
    gap: 4px;
    background-color: #fff;
    /*min-width: min(450px, 88vw);*/
}

.dialogRow {
    display: flex;
    flex-direction: row;
}

.center {
    justify-content: center;
}
