.header {
    width: 100%;
    max-width: 400px;

    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem;
    padding: 0.2rem 0.4rem;

    display: flex;

    flex-flow: row;
    justify-content: space-between;

    background-color: rgba(54, 48, 48, 0.8);
}

.header__item {
    /*display: flex;*/
    /*flex-flow: column;*/
    /*margin: 4px;*/
    padding: 0.4rem 0.4rem 0.2rem;
    text-align: center;
    border-radius: 8px;
    /*flex-grow: 1;*/
    /*flex-basis: 0;*/
    /*width: 70px;*/
}

.header__item:active {
    background-color: #B5DEEF79;
}

.header__item__current {
    background-color: #B5DEEF79;
}

.header__item__icon {
    color: #E0E0E0;
    /*margin-top: 8px;*/
    flex-grow: 1;
    flex-basis: 0;
}



.coinsContainer {
    display: grid;

    grid-template-columns: 1fr 1fr;

    width: 75%;
}
.coinContainer {
    display: flex;
    line-height: 0.5rem;
    padding: 0;
    margin: 0;

    .coin {
        min-width: 4rem;
        align-content: center;

        color: #9c9b9b;
    }

    .balance {
        min-width: 5rem;
        font-weight: 500;
        align-content: center;

        color: #E0E0E0;
    }
}