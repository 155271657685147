.statView {
    display: flex;
    flex-flow: column;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 0.2rem;
}

.statView__title {
    padding: 0 0.2rem;
    /*font-size: 10px;*/
    font-size: 0.8rem;

    color: #696868;
    justify-content: start;
}

.statView__text {
    padding: 0.1rem 0.2rem;
    font-size: 0.9rem;

    color: #E0E0E0;
    justify-content: start;
}

.statView_horizontal {
    background-color: rgba(54, 48, 48, 0.8);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius: 0.3rem;
    padding: 0.3rem;
    border: 1px solid #E0E0E0;
}

.statView_horizontal__title {
    color: #9c9b9b;
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
    padding-left: 0.2rem;
}

.statView_horizontal__text {
    border: none;
    background-color: #9c9b9b;
    display: flex;
    justify-content: center;
    flex-flow: row;
    border-radius: 0.3rem;
    padding: 0.1rem 0.2rem;
}
