.dialog {
    background: #37474F;
    border-radius: 0.3rem;
    border: 1px solid #E0E0E0;
    padding: 0.3rem;
    position: unset;
    display: flex;
    max-height: 80%;
    flex-flow: column;
    min-width: min(450px, 88vw);

}

.dialog__wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(54, 48, 48, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.dialog_hidden {
    display: none;
}

.dialog__content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
}

.closeButtonContainer {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    marigin-left: auto
}

.closeButton {
    /*min-height: 12px;*/
    background: #fd6161;
    margin-bottom: 0.2rem;
    color: white;
    font-size: 1rem;
    padding: 0.3rem;
    border-radius: 1rem;
}
