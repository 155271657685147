body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #212121;
    height: 100%;
    box-sizing: border-box;
}

html {
    height: 100%;
}

/*disable error window (useful for testing in the browser)*/
iframe {
    display: none;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.pinned-right {
    margin-left: auto;
}

.center {
    justify-content: center;
}

.react-player video {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
}
