.counter {
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: center;
}

.counterValue {
    border-radius: 8px;
    color: #E0E0E0;
    text-align: center;
    background: #253237FF;
    padding: 2px 4px;
    border: 1px solid #E0E0E0;
}

.counterValue__current {
    font-size: 14px;
}

.counterValue__maxValue {
    font-size: 10px;
    color: #9E9E9E;
}

.counterButton {
    display: flex;
    padding: 6px;
    border-radius: 100px;
    background: #0E1E2577;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
}

.counterButton:hover {
    background: #253237FF
}
