.vendor {
    padding: 6px;
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    line-height: 1.4rem;
}

.row {
    display: flex;
    /*flex-flow: row;*/
    justify-content: flex-start;
}

.title {
    color: white;
    /*font-weight: bold;*/
    font-size: 16px;
}

.count {
    font-size: 0.8rem;
    /*color: #5e5c5c;*/
    /*color: #9E9E9E;*/
    color: #9E9E9E;
}
.description {
    font-size: 0.8rem;
    /*color: #5e5c5c;*/
    /*color: #9E9E9E;*/
    color: #9E9E9E;

}

.price {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    color: #E0E0E0;
}

.debuffText {
    color: #FFC302;
}

.debuff {
    border: 1px solid #FF5252;
    padding: 0.1rem 0.2rem;
    background-color: #ECEFF1;
    border-radius: 4px;
    /*font-size: 10px;*/
    font-size: 0.7rem;
    font-weight: bolder;
    color: #FF5252;
}

.noDebuff {
    border: 1px solid #33691E;
    color: #33691E;
}

.vendor_new {
    /*color: #4A148C;*/
    /*border: 1px solid #4A148C;*/
    /*background-color: #F3E5F5;*/
    background-color: #424242;
    border: 1px solid #E0E0E0;
}

.vendor_ali {
    /*color: #1B5E20;*/
    /*border: 1px solid #1B5E20;*/
    /*background-color: #F1F8E9;*/
    background-color: #424242;
    border: 1px solid #E0E0E0;
}

.vendor_avito {
    /*color: #F9A825;*/
    /*border: 1px solid #F9A825;*/
      /*background-color: #FFFDE7;*/
    background-color: #424242;
    border: 1px solid #E0E0E0;
}

.vendor_friend {
    /*color: #D50000;*/
    /*border: 1px solid #D50000;*/
    /*background-color: #FBE9E7;*/
    background-color: #424242;
    border: 1px solid #E0E0E0;
}
