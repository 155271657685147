.stats_top {
    display: flex;
    /*margin-bottom: 10px;*/
}

.stats__verticalList {
    display: flex;
    flex-flow: column;
    gap: 0.2rem;
    margin-right: 0.3rem;
}

.stats__bottomList {
    overflow-x: auto;
    display: grid;
    gap: 0.2rem;
    grid-template-columns: 1fr 1fr;
}

.stats__divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 10px 0;
}

.stats__chart {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

.stats__switch {
    color: #E0E0E0;
    font-size: 10px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
}

.stats__switch_active {
    text-decoration: underline;
    cursor: pointer;
}
