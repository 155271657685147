.topContainer {
    height: 100%;
    /*background-color: yellow;*/
}

.container {
    /*position: absolute;*/
    /*width: 100%;*/
    /*max-width: 400px;*/

    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/

    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #424242;
    /*overflow-x: hidden;*/
    /*overflow-y: hidden;*/
    padding: 0.3rem;
    /*background-color: red;*/
    user-select: none; /* disable text selection */
}

.header {
    display: flex;
    justify-content: center;
    color: #E0E0E0;
    font-weight: bold;
    line-height: 2rem;
}

.categoryContainer {
    display: grid;
    grid-gap: 0.2rem;
    grid-template-columns: 1fr 1fr;

    user-select: none; /* disable text selection */
}
.dataContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #424242;
    padding: 0.3rem;

    /*flex: 1;*/
    /*background-color: #0b8491;*/
    margin-top: 0.2rem;
}

.user {
    display: flex;
    flex-direction: row;

    color: #E0E0E0;
    line-height: 1.5rem;
    /*flex-grow: 1;*/
    /*width: 100%*/
    font-weight: bold
}

.userPlace {
    margin-right: 0.5rem;
    min-width: 3rem;
}

.userName {

}

.userValue {
    margin-left: auto;
}

.placeSpacer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0.4rem;
    color: #E0E0E0;
    font-weight: bold;

}
