.container {
    display: flex;
    flex-direction: column;
    /*flex-wrap: nowrap;*/
    gap: 4px;
    min-width: min(300px, 90vw);

}

.row {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: nowrap;*/
    /*flex-grow: 1;*/
}

.nftContainer {
    display: flex;
    /*flex-flow: row;*/
    flex-direction: column;
    flex-wrap: wrap;
    /*justify-content: ;*/
    gap: 4px;
    justify-content: flex-end;
    user-select: none; /* disable text selection */
}

.buttonsContainer {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    color: #E0E0E0;
    align-content: center;
}

